<template>
  <div class="container">

    <b-modal
      size="lg"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <PropertyGroupEditor
        :item="item"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="created"
        @updated="updated"
      />

    </b-modal>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';

import PropertyGroupEditor from './PropertyGroupEditor.vue';


export default {

  name: 'PropertyGroupModal',

  props: ['item'],

  mixins: [ toasts ],
  emits: ['created', 'updated'],

  components: {
    PropertyGroupEditor
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    created(item) {
      this.$emit('created', item);
    },
    updated(item) {
      this.$emit('updated', item);
    }
  },

  mounted() {


  },

  data() {
    return {


    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>

